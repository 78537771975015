import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/home.vue")
    },
    {
        path: "/detail/:hash",
        name: "detail",
        component: () => import("../views/detail.vue"),
        props: true
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login.vue")
    },
    {
        path: "/register",
        name: "register",
        component: () => import("../views/register.vue")
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("../views/profile.vue")
    },
    {
        path: "/add",
        name: "add",
        component: () => import("../views/project/add.vue")
    },
    {
        path: "/:username",
        name: "overview",
        component: () => import("../views/overview.vue"),
        props: true
    },
    {
        path: "/edit",
        name: "edit",
        component: () => import("../views/project/edit.vue"),
        children: [
            {
                path: "/:hash",
                name: "edit-single",
                component: () => import("../views/project/edit-single.vue"),
                props: true,
            }
        ]
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
