import Axios from "axios";
import createPersistedState from "vuex-persistedstate";

const getDefaultState = () => {
    return {
        user: {},
        isAuthenticated: !!window.localStorage.getItem('id_token')
    };
};

export default {
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
            window.localStorage.setItem('id_token', token);
        },
        SET_USER: (state, user) => {
            state.user = user;
            console.log(user);
            console.log(state.user);
            state.isAuthenticated = true
        },
        RESET: state => {
            window.localStorage.removeItem('id_token');
            state.isAuthenticated = false
            Object.assign(state, getDefaultState());
        }
    },
    actions: {
        login: ({ commit, dispatch }, { token, user }) => { // eslint-disable-line
            commit('SET_TOKEN', token);
            commit('SET_USER', user);
            
            // set auth header
            Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },
        logout: ({ commit }) => {
            commit('RESET', '');
        }
    },
        getters: {
        isAuthenticated: state => {
            return state.isAuthenticated; 
        },
        currentUser: state => {
            return state.user;
        }
    }
};