import axios from "axios"

const url = 'http://localhost:3000'

export default {
    getAllProjectsForCurrentUser() {
        return axios
        .get(url + '/projects')
        .then(response => response.data)
    },
    getAllProjectsForUserWithUsername(username) {
        return axios
            .get(url + `/projects/${username}`)
            .then(response => response.data)
    },
    get(hash) {
        return axios
            .get(url + `/projects/${hash}`)
            .then(response => response)
    },
    create(details) {
        return axios
            .post(url + '/projects', details)
            .then(response => response.data)
    }
}