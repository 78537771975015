import ProjectService from "../services/ProjectService"
import { FETCH_PROJECTS, FETCH_PROJECTS_USERNAME } from "./actions.type"
import { FETCH_START, FETCH_END } from "./mutations.type"

const state = {
    isLoading: true,
    projects: []
}

const mutations = {
    [FETCH_START](state) {
        state.isLoading = true;
    },
    [FETCH_END](state, projects) {
        state.projects = projects;
        console.log(projects)
        console.log(state.projects)
        state.isLoading = false;
    }
}

const actions = {
    [FETCH_PROJECTS]({ commit }) {
        commit(FETCH_START);

        return ProjectService.getAllProjectsForCurrentUser()
        .then(({ data }) => {
            commit(FETCH_END, data)
        })
        .catch(error => {
            throw new Error(error);
        })
    },
    [FETCH_PROJECTS_USERNAME]({ commit, username }) {
        commit(FETCH_START);

        console.log(username)
        return ProjectService.getAllProjectsForUserWithUsername(username)
        .then(({ data }) => {
            console.log(data)
            commit(FETCH_END, data)
        })
        .catch(error => {
            throw new Error(error);
        })
    }
}

const getters = {
    projects(state) {
        return state.projects
    },
    isLoading(state) {
        return state.isLoading
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}