import ProjectService from "../services/ProjectService";
import { FETCH_PROJECT } from "./actions.type"
import { SET_PROJECT } from "./mutations.type"

const getDefaultState = () => {
    return {
        project: {}
    }
}

const mutations = {
    [SET_PROJECT](state, project) {
        state.project = project;
    }
}

const actions = {
    async [FETCH_PROJECT](context, hash) { // eslint-disable-line
        const project = await ProjectService.get(hash);
        context.commit(SET_PROJECT, project.data);
        return project;
    }
}

const getters = {
    project(state) {
        return state.project;
    }
}

export default {
    state: getDefaultState(),
    mutations,
    actions,
    getters
}