import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'

import auth from "./auth.module";
import project from "./project.module";
import overview from "./overview.module";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],
    modules: {
        auth,
        project,
        overview
    }
})